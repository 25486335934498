import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

export default styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid ${styleguide.backgroundColor.button};
`
