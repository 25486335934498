import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface rowStyles {
	totalWidth: boolean
	small: boolean
}

export default styled.div<rowStyles>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: ${({totalWidth}) => totalWidth ? 'no-wrap' : 'wrap'};

	margin: ${({small}) => small ? 0 : styleguide.margin.form};
	width: ${({totalWidth}) => totalWidth ? '100%' : 'unset'};

	& p {
		text-align: left;
	}
`
