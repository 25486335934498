import React, { useEffect, useRef, useState } from 'react'
import Container from '../../../../components/Container'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button'
import { Response, uploadRequest } from '../../../../global/Api'
import Popup from '../../../../components/Popup'
import BackButton from '../../../../components/Button/Back'

export default function ConfigReports(){

	const fileInputRef = useRef() as any
	const [popupResponse, setPopupResponse] = useState({} as Response)

	const [rebelButtonText, setRebelButtonText] = useState('Rebels')

	useEffect(() => {

		setInterval(() => {

			console.log(123)
			setRebelButtonText('Date.now().toString()')

		}, 1000)

	}, [])

	async function handleChangeRebelFile(event: any){

		const file = event.target.files[0]

		if(file){

			setRebelButtonText('Carregando...')

			const fileData = new FormData()
			fileData.append('file', file)
			fileData.append('filename', 'rebeldata1.xlsx')

			const response = await uploadRequest(
				'/assets/upload/rebeldata',
				fileData,
				'admin'
			)

			setPopupResponse(response)

			response.status == 200
				? setRebelButtonText('Sucesso')
				: setRebelButtonText('Erro')

		}

	}

	return (
		<>
			<BackButton />
			<Container>
				<Text
					textOption='title'
				>
					Estatísticas
				</Text>
			</Container>
			<Container>
				<Text
					textOption='subtitle'
				>
					Atualizar Arquivos
				</Text>

				<Button
					onclick={()=> { if(fileInputRef.current && fileInputRef.current.click) fileInputRef.current.click() }}
				>
					{rebelButtonText}
				</Button>
				<input
					onChange={handleChangeRebelFile}
					multiple={false}
					ref={fileInputRef}
					type='file'
					hidden
				/>
			</Container>

			<Popup data={popupResponse} />
		</>
	)

}
