import styled, { keyframes } from 'styled-components'
import { styleguide } from '../../global/Theme'

const expand = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
`

interface CircleProps {
	left?: boolean
}

export const StyledCircle = styled.img<CircleProps>`
	border-radius: 100%;

	width: 50px;
	height: 50px;

	position: fixed;
	bottom: 20px;
	right: 20px;
	left: ${
		({ left }) => left
		? '20px'
		: 'auto'
	};

	&:hover, &:active {
		animation: ${expand} 1.25s linear infinite;
	}
`
