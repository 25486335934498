import React from 'react'
import VerticalContainer from './styles'

export default function Row({
	children,
	totalWidth = false,
	small = false,
} : {
	children: any
	totalWidth?: boolean
	small?: boolean
}){

	return (
		<VerticalContainer
			totalWidth={totalWidth}
			small={small}
		>
			{children}
		</VerticalContainer>
	)

}
