import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { styleguide } from '../../global/Theme'

const DefaultLink = styled(Link).attrs(props => ({
	as: props.as || Link,
	download: props.download || undefined,
	href: props.download ? props.to : undefined,
}))`
	text-decoration: none;
	color: ${styleguide.color};
	font-weight: ${styleguide.fontWeight.link};

	margin-left: ${styleguide.margin.link};

	&:hover {
		text-decoration: underline;
	}
`

export default DefaultLink
