import React, { useEffect, useState } from 'react'
import Text from '../../../components/Text'
import Container from '../../../components/Container'
import BackButton from '../../../components/Button/Back'
import ApiRequest from '../../../global/Api'
import Loading from '../../../components/Loading'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { styleguide } from '../../../global/Theme'
import Column from '../../../components/Column'
import Button from '../../../components/Button'

Chart.register(CategoryScale)

export default function Reports(){
	// https://blog.logrocket.com/using-chart-js-react/
	// https://vb.rebelbetting.com/bets

	const [reportsData, setReportsData] = useState({} as any)
	const [timeDate, setTimeDate] = useState('month')

	async function getData(timeData = ''){
		// 'forever'

		if(timeData) setTimeDate(timeData)

		const response = await ApiRequest('/user/get/reports/bet365', { timeData })

		if(response.status != 400){

			const totalProfits: number[] = []
			const totalCLVProfits: number[] = []

			const profits = Object.values(response.data.betsOnDate).map((el: any) => parseFloat(el.profit))
			const CLVProfits = Object.values(response.data.betsOnDate).map((el: any) => parseFloat(el.CLVProfit))

			// console.log({ profits })

			for(let i = 0; i < profits.length; i++){

				totalProfits.push(
					(totalProfits[i - 1] || 0) + profits[i]
				)

				totalCLVProfits.push(
					(totalCLVProfits[i - 1] || 0) + CLVProfits[i]
				)

			}

			console.log({ data: response.data })

			setReportsData({
				...response.data,
				graphics: {
					totalProfits,
					totalCLVProfits
				}
			})

		}

	}

	useEffect(() => {

		setReportsData({
			timeAbout: '',
			totalBets: 0,
			totalProfit: 0,
			averageOdds: 0,
			ROI: 0,
			averageCLV: 0,
			averagePercentage: 0,
			betsOnDate: [] as any[],
			graphics: {
				totalCLVProfits: [] as number[],
				totalProfits: [] as number[]
			}
		})

		getData(timeDate)

	}, [timeDate])

	useEffect(() => {

		getData()

	}, [])

	const generalDataStyle: React.CSSProperties = {
		margin: '8px',
		padding: '12px',
		width: '35%',
		border: '1px solid white',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	}

	const translateTableTitle = {
		odds: 'Odds',
		fairOdds: 'Aposta de Valor',
		result: 'Resultado'
	}

	const translateResultTable = {
		Win: '✅',
		HalfWin: '🔄/✅',
		Lose: '❌',
		HalfLose: '🔄/❌',
		Void: '🔄',
	}

	const resultsLengthIndex = !reportsData.totalBets ? 0 : parseInt((reportsData.graphics.totalProfits.length / 10).toFixed(0))

	return (
		<>
			<BackButton />
			<Container>
				<Text
					textOption='title'
				>
					Estatísticas em Tempo Real
				</Text>
			</Container>

			<Container>
				<Text
					textOption='subtitle'
				>
					Dados Gerais Bet365
				</Text>
				{
					reportsData.totalBets > 0  ? (
						<>
							<br />
							<Text
								textOption='subtitle'
							>
								Período de referência: {reportsData.timeAbout}
							</Text>
							<br />
							<div style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
							}}>
								<div style={generalDataStyle}>
									Banca Inicial:
									<br />
									R$10.000,00
								</div>
								<div style={generalDataStyle}>
									Stake:
									<br />
									R$100,00 (1%)
								</div>
								<div style={generalDataStyle}>
									Lucro total:
									<br />
									<span style={{
										color: reportsData.totalProfit >= 0 ? styleguide.backgroundColor.success : styleguide.backgroundColor.error,
										fontWeight: 700
									}}>
										R${
											reportsData.totalProfit.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
										} / {
											(reportsData.totalProfit / 100).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
										} unidades
									</span>
								</div>
								<div style={generalDataStyle}>
									Qtd apostas:
									<br />
									{reportsData.totalBets}
								</div>
								<div style={generalDataStyle}>
									Odds médias:
									<br />
									{reportsData.averageOdds.toFixed(2)}
								</div>
								<div style={generalDataStyle}>
									ROI:
									<br />
									{(reportsData.ROI * 100).toFixed(2)}%
								</div>
								<div style={generalDataStyle}>
									CLV médio:
									<br />
									{(reportsData.averageCLV * 100).toFixed(2)}%
								</div>
								<div style={generalDataStyle}>
									Porcentagem média:
									<br />
									{(reportsData.averagePercentage * 100).toFixed(2)}%
								</div>
							</div>
						</>
					) : <Loading />
				}

			</Container>

			<Container>
				<Text
					textOption='subtitle'
				>
					Análise Gráfica
				</Text>
				{
					reportsData.totalBets > 0  ? (
						<div
							style={{
								margin: '0 auto',
								width: '80vw',
								maxWidth: '600px',
								height: 'auto',
							}}
						>
							<Line
								data={{
									labels: Object.keys(reportsData.betsOnDate).reverse().filter((el: any, index: number) => index % resultsLengthIndex == 0),
									datasets: [
										{
											label: 'Lucro Total Esperado',
											data: reportsData.graphics.totalCLVProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
											backgroundColor: [
												'#84b5ff',
											],
											pointBackgroundColor: '#0066ff',
											borderColor: '#0066ff',
											borderWidth: 2,
											tension: 0.4,
										},
										{
											label: 'Lucro Total',
											data: reportsData.graphics.totalProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
											backgroundColor: [
												'rgb(136, 191, 77)',
											],
											pointBackgroundColor: '#007902',
											borderColor: '#007902',
											borderWidth: 2,
											fill: true,
											tension: 0.4,
										},
									]
								}}
								options={{
									plugins: {
										title: {
											display: true,
											text: `Lucros de: ${reportsData.timeAbout}`
										}
									}
								}}
							/>
						</div>
					) : <Loading />
				}
			</Container>

			<Column>
				<Button onclick={() => setTimeDate('forever')} tosuccess={timeDate == 'forever'} >
					Resultados desde o início
				</Button>
				<Button onclick={() => setTimeDate('year')} tosuccess={timeDate == 'year'}>
					Resultados dos últimos 12 meses
				</Button>
				<Button onclick={() => setTimeDate('trimester')} tosuccess={timeDate == 'trimester'}>
					Resultados dos últimos 3 meses
				</Button>
				<Button onclick={() => setTimeDate('sixmester')} tosuccess={timeDate == 'sixmester'}>
					Resultados dos últimos 6 meses
				</Button>
				<Button onclick={() => setTimeDate('month')} tosuccess={timeDate == 'month'}>
					Resultados dos últimos 30 dias
				</Button>
				<Button onclick={() => setTimeDate('week')} tosuccess={timeDate == 'week'}>
					Resultados dos últimos 7 dias
				</Button>
			</Column>

			<Container>
				<Text
					textOption='subtitle'
				>
					Ultimas 10 apostas
				</Text>
				{
					!reportsData.last10Bets ? <Loading />
						: reportsData.last10Bets.length == 0 ? <Text>Sem apostas recentes</Text>
							: (
								<table style={{
									margin: '12px auto',
									padding: '4px',
									borderCollapse: 'collapse'
								}}>
									<tr>
										{Object.keys(reportsData.last10Bets[0]).map((el) => (
											<th style={{
												width: '150px',
												padding: '6px 0',
												border: '1px solid white',
											}}>
												{translateTableTitle[el as keyof typeof translateTableTitle]}
											</th>
										))}
									</tr>
									{
										reportsData.last10Bets.map((el: any) => (
											<tr>
												<td style={{
													border: '1px solid white'
												}}>
													{parseFloat(el['odds']).toFixed(2)}
												</td>
												<td style={{
													border: '1px solid white'
												}}>
													+EV
												</td>
												<td style={{
													border: '1px solid white'
												}}>
													{translateResultTable[el['result'] as keyof typeof translateResultTable]}
												</td>
											</tr>
										))
									}
								</table>
							)

				}
			</Container>
		</>
	)

}
