import React, { useEffect, useRef, useState } from 'react'
import Container from '../../../components/Container/styles'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import ApiRequest, { Response } from '../../../global/Api'
import Column from '../../../components/Column'
import BackButton from '../../../components/Button/Back'
import Input, { InputChange } from '../../../components/Input/Text'
import Loading from '../../../components/Loading'
import Popup from '../../../components/Popup'
import { updateToken } from '../../../global/Token'
import RadioInput from '../../../components/Input/Radio'
import { styleguide } from '../../../global/Theme'
import { useDomainConfig } from '../../../global/Domain'

export default function Plans(){

	const views = [
		<ContainerChooseBot />,
		<QuantitySubscriptions />,
		<PaymentMethod />,
		<GeneratePixPayment />,
		<PopupUpdateDocument />
	]

	const domainConfig = useDomainConfig()

	const document = useRef('')

	const [popupResponse, setPopupResponse] = useState({} as Response)

	const [viewContainer, setViewContainer] = useState(0)
	const [buttonActived, setButtonActived] = useState(true)
	const [copyButtonActived, setCopyButtonActived] = useState(true)

	const [selectedPlan, setSelectedPlan] = useState('')
	const [selectedPlanVariation, setSelectedPlanVariation] = useState(0)
	const [quantitySubscriptions, setQuantitySubscriptions] = useState('1')


	const [planData, setPlanData] = useState([{
		name: '',
		subscriptionValidityDays: 0,
		price: {
			unit: 297,
			variation: [ {
				id: '#0',
				limits: null,
				unit: 249,
				title: 'Ilimitado'
			} ]
		},
	}])
	const [paymentMethod, setPaymentMethod] = useState('')

	const [paymentData, setPaymentData] = useState({
		isError: false,
		idTransaction: '',
		paymentCode: '',
		paymentCodeBase64: '',
		newSubscriptionsName: '',
		currentQuantity: 0,
		renovationValue: 0,
		renovationDays: 0,
		newValue: 0,
		newSubscriptionsQuantity: 0,
		finalValue: 0,
	})

	useEffect(() => {

		getPlansData()
		// getMyPlanData()

	}, [])

	useEffect(() => {

		if(['Valuebet', 'Rollover', 'Alavancagem'].includes(selectedPlan)) setViewContainer(1)
		else setViewContainer(0)

	}, [selectedPlan])

	useEffect(() => {

		quantitySubscriptions == '0'
			? setButtonActived(false)
			: setButtonActived(true)

	}, [quantitySubscriptions])

	useEffect(() => {

		if(paymentMethod == 'pix') setViewContainer(3)

		requestPayment()

	}, [paymentMethod])

	async function getPlansData(){

		const response = await ApiRequest('global/show/plans')

		response.status == 400
			? setPopupResponse({ ...response, msg: 'Erro ao listar planos, recarregue a página.' })
			: setPlanData(response.data)

	}

	async function requestPayment(){

		const plan = planData.find(plan => plan.name == selectedPlan)

		let value = plan?.price?.unit
		let variation: string | null = null

		if(plan?.price?.variation){

			value = plan.price.variation[selectedPlanVariation].unit
			variation = plan.price.variation[selectedPlanVariation].id

		}

		const response = await ApiRequest(`payment/create/${paymentMethod}`, {
			product: {
				name: selectedPlan,
				variation,
				quantity: parseInt(quantitySubscriptions),
				value,
			}
		}, 'user')

		console.log({response})

		if(response.status == 400){

			if(response.data?.required == 'doc') setViewContainer(4)
			else setPaymentData(response.data)  // setPopupResponse(response)

		}

		setPaymentData(response.data)

	}

	async function updateDocument(){

		const response = await ApiRequest('user/update/document', { document: document.current }, 'user')

		if(response.status == 400) setPopupResponse(response)
		else{

			const responseToken = await updateToken()

			if(responseToken?.status == 400) setPopupResponse(responseToken)
			else{

				setViewContainer(3)
				requestPayment()

			}

		}

	}

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='subtitle'>
				Contrate um plano
				</Text>
			</Container>
			{/* <img src={base64Image} alt="Código PIX" /> */}
			{
				views[viewContainer]
			}
			<Popup data={{...popupResponse}}  />
		</>
	)

	function ContainerChooseBot(){

		return (
			<Container>
				<Column>
					<Text textOption='subtitle'>
						Qual Bot quer contratar?
					</Text>
					<Button active={false} onclick={() => setSelectedPlan('Rollover')}>
						Rollover
					</Button>
					<Button onclick={() => setSelectedPlan('Valuebet')}>
						Valuebet
					</Button>
					<Button active={false} onclick={() => setSelectedPlan('Alavancagem')}>
						Alavancagem
					</Button>
				</Column>
			</Container>
		)

	}

	function QuantitySubscriptions(){

		const plan = planData.find(plan => plan.name == selectedPlan)

		const handleRadioChange = (index: number) => setSelectedPlanVariation(index)

		const price = plan
			? plan['price']['variation'][selectedPlanVariation]['unit']
			: 0

		const replaceTitle = domainConfig.writings.plans.valuebet.replace

		return (
			plan ? <Container>
				<Column>
					<Input type='number' defaultValue={quantitySubscriptions} onChange={(event: any) => setQuantitySubscriptions(event.target.value)}>
					Quantos Bots quer assinar?
					</Input>
					<RadioInput
						name={'valuebetExpections'}
						selectedIndex={selectedPlanVariation}
						onChange={handleRadioChange}
						optionsArray={
							plan['price']['variation']
								// .filter((el: any) => !valuebetInvisibleIDs.includes(el.id))
								.map((el: any) => {
									return { name: `${replaceTitle[el.id] || el.title} (${el.unit}/mês)` }
								})
						}
						isRow
						small={true}
					/>
					<Text textOption='subtitle'>
					Valor da mensalidade: <span style={{
							color: styleguide.backgroundColor.success,
							textDecoration: 'underline'
						}}>R${(parseInt(quantitySubscriptions) * price).toFixed(2)}</span> ({plan.subscriptionValidityDays} dias válidos)
					</Text>
					<Button active={buttonActived} onclick={() => setViewContainer(2)}>
					Gerar Pagamento
					</Button>
					<Button onclick={() => setSelectedPlan('')}>
					Contratar outro plano
					</Button>
				</Column>
			</Container>
				: <Text textOption='title'>Recarregue a página. Se persistir, entre em contato com o suporte.</Text>
		)

	}

	function PaymentMethod(){

		return (
			<Container>
				<Text textOption='subtitle'>
					Selecione o método de pagamento
				</Text>
				<Column>
					<Button onclick={() => setPaymentMethod('pix')}>
					Pix
					</Button>
					<Button active={false} onclick={() => { null }}>
					Crédito/Débito (em breve)
					</Button>
					<Button active={false} onclick={() => { null }}>
					Boleto (em breve)
					</Button>
					<Button onclick={() => setSelectedPlan('')}>
					Contratar outro plano
					</Button>
				</Column>
			</Container>
		)

	}

	function GeneratePixPayment(){

		const base64Image = `data:image/png;base64,${paymentData.paymentCodeBase64}`

		const paymentDetails = () => {

			return (
				<Column>
					<Text textOption='subtitle' haveMargin>
					Detalhes do pagamento:
					</Text>
					<Text fullWidth haveMargin>
						Plano atual: {paymentData.renovationDays.toFixed(2)} dias usados em {paymentData.currentQuantity} {paymentData.currentQuantity != 1 ? 'bots' : 'bot'}
						<br />
						(necessário renovar essa quantidade de dias para contratar um novo plano)
					</Text>
					<Text fullWidth>
						Valor da renovação dos dias usados: {paymentData.renovationValue} reais
					</Text>
					<Text fullWidth>
						Nova contratação: {paymentData.newValue} reais ({paymentData.newSubscriptionsQuantity} {paymentData.newSubscriptionsQuantity != 1 ? 'bots' : 'bot'} {paymentData.newSubscriptionsName})
					</Text>
					<Text textOption='subtitle' haveMargin fullWidth>
						Valor total final: <span style={{
							color: styleguide.backgroundColor.success,
							textDecoration: 'underline'
						}}>{paymentData.finalValue} reais</span>
					</Text>
				</Column>
			)

		}

		return paymentData.isError ? (
			<Container>
				<Text textOption='title' haveMargin fullWidth>
					Houve um erro, tente novamente.
				</Text>
				<Text textOption='subtitle' haveMargin fullWidth>
					Caso persista, contate o suporte.
				</Text>
			</Container>
		) : paymentData?.idTransaction ? (
			<Container>
				<Column>
					{paymentData.renovationValue != 0 && paymentDetails()}
					<Text textOption='subtitle' haveMargin >
					QR CODE
					</Text>
					<img src={base64Image} alt="Código PIX" />
					<Text fullWidth haveMargin >
						{paymentData.paymentCode}
					</Text>
					<Button active={copyButtonActived} onclick={() => {

						navigator.clipboard.writeText(paymentData.paymentCode)
						setCopyButtonActived(false)

					}}>
						{copyButtonActived ? 'Copiar Código' : 'Copiado'}
					</Button>
				</Column>
			</Container>
		) : <Container><Loading /></Container>

	}

	function PopupUpdateDocument(){

		return (
			<Container>
				<Column>
					<Input onChange={(event: InputChange) => document.current = event.target.value}>
					Insira seu CPF
					</Input>
					<Button onclick={() => updateDocument()}>
					Atualizar
					</Button>
					<Text>
					OBS: Não precisa colocar simbolos ("/", ".", "-")
					</Text>
				</Column>
			</Container>
		)

	}

}
