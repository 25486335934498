import React from 'react'
import StyledIframe from './styles'

export default function Iframe({
	title,
	src,
	fromYoutube = true
} : {
	title: string,
	src: string,
	fromYoutube?: boolean,
}){

	const finalSrc = fromYoutube ? `https://www.youtube.com/embed/${src}` : src

	return (
		<StyledIframe
			title={title}
			sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
			src={finalSrc}>
		</StyledIframe>
	)

}
