import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface inputStyles {
	small: boolean
}

interface containerStyles {
	checked?: boolean
}

export const InputText = styled.input.attrs(props => ({
	placeholder: props.placeholder
}))<inputStyles>`
	margin: ${({small}) =>
		small ? '0 10px'
		// : extrasmall ? '0 4px'
		: styleguide.margin.input
	};
	padding: ${({small}) =>
		small ? '2px'
		// : extrasmall ? '1px'
		: styleguide.padding.input
	};
	border: ${styleguide.border.input};
	border-radius: ${styleguide.borderRadius.input};

	width: ${({small}) =>
		small ? '50%'
		// : extrasmall ? '30%'
		: '80%'
	};
	max-width: ${({small}) =>
		small ? '150px'
		// : extrasmall ? '80px'
		: '300px'
	};
	min-width: ${({small}) =>
		small ? '100px'
		// : extrasmall ? '80px'
		: '200px'
	};

	transition: border-color 0.3s ease;

	&:focus {
		outline: none;
	}

	&:disabled {
		cursor: not-allowed;
		background-color: #777777;
		border: 1px solid black;
	}
`

export const InputEmail = styled(InputText).attrs({type: 'email'})`

`

export const InputPassword = styled(InputText).attrs({type: 'password'})`

`
export const InputNumber = styled(InputText).attrs({type: 'number'})`

`

export const InputRadio = styled.input.attrs({type: 'radio'})<inputStyles>`
	margin-right: 4px;
`

export const InputSelect = styled(InputText).attrs({ as: 'select' })<inputStyles>`

`

export const ContainerRadio = styled.div<containerStyles>`
	display: flex;
	padding: ${styleguide.padding.input};
	margin: ${styleguide.margin.input};
	background-color: ${({checked}) => checked
		? styleguide.backgroundColor.editButton
		: styleguide.backgroundColor.button
	};
	border-radius: ${styleguide.borderRadius.input};

	cursor: pointer;
	width: 200px;
`
