import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface ButtonProps {
	small?: boolean
	toedit?: boolean
	tosuccess?: boolean
	todelete?: boolean
	isfixed?: boolean
}

const Button = styled.button<ButtonProps>`
	background-color: ${({todelete, toedit, tosuccess}) =>
		todelete ? styleguide.backgroundColor.error
		: toedit ? styleguide.backgroundColor.editButton
		: tosuccess ? styleguide.backgroundColor.success
		: styleguide.backgroundColor.button
	};
	font-size: ${styleguide.fontSize.button};
	border-radius: ${styleguide.borderRadius.button};

	margin: ${styleguide.margin.button};
	padding: 10px 20px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s ease;

	width: ${({small}) => small ? '40%' : '80%'};
	max-width: ${({small}) => small ? '150px' : '300px'};

	${({isfixed}) => isfixed && `
		position: fixed;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
	`}

	&:hover {
		background-color: ${({todelete, toedit, tosuccess}) =>
			todelete ? styleguide.hover.error.background
			: toedit ? styleguide.hover.editButton.background
			: tosuccess ? styleguide.hover.success.background
			: styleguide.hover.button.background
		}
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	& a {
		color: white;
	}
`

export const StyledBackButton = styled(Button)`
	display: block;
	margin-right: auto;

  	width: 150px;
`

export default Button
