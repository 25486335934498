import React from 'react'
import Text from '../../../../components/Text'
import BackButton from '../../../../components/Button/Back'
import Container from '../../../../components/Container/styles'

export default function ConfigChatBot(){

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='title'>
				Configure o ChatBot
				</Text>
			</Container>
		</>
	)

}
