import React, { createContext, useContext } from 'react'

export const domainConfigs: { [key: string]: any } = {
	'botbuscagreenoficial.com': {
		link: {
			suitpayAffiliation: '',
			whatsapp: 'https://api.whatsapp.com/send/?phone=%2B5577999948001&text&type=phone_number&app_absent=0',
			instagram: 'https://abrir.link/AWSlU',
			classes: [
				'MVfU9wm_qng?si=OfjHK6Oyq9Uh5olD',
				'v6tr1mEsFfY?si=HBYqCbJ6mO43dHrr',
				'yiioFKIbaXc?si=eTsSHFHvyPgrRfn',
				'Odgu8xdhre8?si=309IpZ_0aU8nnOFr',
				'2UEOx6gz2VE?si=RK3C9786jMyMtBOO',
				'5Am8DM4b8vA?si=JrS4_rbLPy1yHY1P',
				'CnHvFVHrF_w?si=qhvoIqHcTbHXAS5B',
				'Mm8Ch21ywB4?si=EwZ0PDQqcDcD05u8',
			],
			vpsPartner: 'https://central.ominihost.com.br/store/vps-advanced/vps-buscagreen',
		},
		writings: {
			title: 'Bot-BuscaGreen Oficial',
			plans: {
				valuebet: {
					replace: {}
				}
			}
		},
		visibleElements: {
			affiliate: true,
			plans: {
				valuebet: {
					invisibleIDs: [''],
				},
			},
			userControlPanel: {
				rollover: true,
				alavancagem: true,
				valuebet: true,
			},
			classes: {
				titles: []
			},
			reports: true,
		},
	},
	'xtradingbotsbrasil.com.br': { },
	'botbuscagreen.com': { },
	'localhost': { },
}

domainConfigs['xtradingbotsbrasil.com.br'] = {
	...domainConfigs['botbuscagreenoficial.com'],
	link: {
		suitpayAffiliation: 'XTradingbotsbrasil', // -> aleronconi
		whatsapp: 'https://t.me/suportextradingbrasil',
		instagram: 'https://instagram.com/luisbarbarossi/',
		classes: [
			'fmbaVThwwdI?si=r4grqmNyF7i-p6AK',
			'kDwyLP7pBaE?si=QKu1cKw_-KPw31wY',
			'm1GQ3P5d2NQ?si=fNArIqp_S20SqYf7',
			'NPzRIweogcw?si=sOafh4POJSb5lBde',
			'bW6JuxiuRlY?si=VcLlRcxnBO7VOxDh',
			'fniurSyrjPY?si=6x60ck5GvIl7ZJVC',
			'EMqLuzXbofE?si=q_RaHZzoLveVD1Nl',
			'3U154ATNpRw?si=XKSDihKsjS9YfSZV',
		],
		vpsPartner: 'https://central.ominihost.com.br/store/vps-advanced/xtradingbotsbrasil-vps',
	},
	writings: {
		title: 'XTradingbotsbrasil',
		plans: {
			valuebet: {
				replace: {
					'#3': 'Stake 5 reais (Apenas contas limitadas)'
				}
			}
		}
	},
	visibleElements: {
		affiliate: false,
		userControlPanel: {
			rollover: false,
			alavancagem: false,
			valuebet: true,
		},
		classes: {
			titles: ['Usando Bot Rollover', 'Usando o Bot Superbet (Valuebet)']
		},
		reports: false,
	},
}

domainConfigs['botbuscagreen.com'] = {
	...domainConfigs['botbuscagreenoficial.com'],
	visibleElements: {
		affiliate: false,
		userControlPanel: {
			rollover: false,
			alavancagem: false,
			valuebet: true,
		},
		classes: {
			titles: ['Usando Bot Rollover', 'Usando o Bot Superbet (Valuebet)']
		},
		reports: false,
	},
}

domainConfigs.localhost = domainConfigs['botbuscagreenoficial.com']
// domainConfigs.localhost = domainConfigs['xtradingbotsbrasil.com.br']
domainConfigs.localhost = domainConfigs['botbuscagreen.com']

const DomainContext = createContext(domainConfigs['botbuscagreenoficial.com'])

export const useDomainConfig = () => { return useContext(DomainContext) }

export function DomainProvider({ children } : { children: any }){
	console.log(window.location.hostname, domainConfigs[window.location.hostname])
	const settedDomain =
		domainConfigs[window.location.hostname]
		|| domainConfigs['botbuscagreenoficial.com']

	document.title = settedDomain.writings.title

	return (
		<DomainContext.Provider value={settedDomain}>
			{children}
		</DomainContext.Provider>
	)

}
