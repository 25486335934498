import React from 'react'
import Container from '../../components/Container/styles'
import Text from '../../components/Text'
import Button from '../../components/Button'

export default function Signin(){

	return (
		<Container>
			<Text textOption='title'>
			Página não encontrada
			</Text>
			<Button redirect onclick={'/'}>
			Voltar ao Início
			</Button>
		</Container>
	)

}
