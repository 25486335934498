import ApiRequest from '../Api'

export default function token(isAdmin = false){

	const token = isAdmin ? 'jwtAdminData' : 'jwtUserData'

	return localStorage.getItem(token)

}

export async function decodeToken(isAdmin = false){

	const response = await ApiRequest(`user/auth/${token(isAdmin)}`)
	console.log({ decodedData: response.data.timestamp })

	return response

}

export async function updateToken(){

	const response = await ApiRequest('user/auth/update', {}, 'user')
	if(response.status == 400) return response

	console.log('Update Token.')

	localStorage.setItem('jwtUserData', response.data.token)

	response.data.haveAdminPermissions
		? localStorage.setItem('jwtAdminData', response.data.token)
		: localStorage.removeItem('jwtAdminData')

}
