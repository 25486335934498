import React from 'react'
import { Spinner, SpinnerContainer } from './styles'

export default function Loading(){

	return (
		<SpinnerContainer>
			<Spinner />
		</SpinnerContainer>
	)

}
