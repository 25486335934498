import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import Container from '../../../components/Container/styles'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import ApiRequest from '../../../global/Api'
import Column from '../../../components/Column'
import Circle from '../../../components/Icons/Circle'
import { useDomainConfig } from '../../../global/Domain'

export default function Home(){

	const domainConfig = useDomainConfig()

	const [name, setName] = useState('')
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {

		getUserData()

	}, [])

	async function getUserData(){

		const userData = await ApiRequest(`user/auth/${localStorage.getItem('jwtUserData')}`)

		if(userData.status == 200){

			const { name, haveAdminPermissions } = userData.data.userData

			if(name) setName(name.split(' ')[0])
			if(haveAdminPermissions) setIsAdmin(true)

		}

	}

	return (
		<>
			<Header />
			<Container>
				<Text textOption='subtitle'>
				Bem Vindo {name}
				</Text>
			</Container>
			<Container>
				<Column>
					<Text textOption='subtitle'>
					Informações da conta
					</Text>
					<Button redirect onclick={'/user/account'}>
					Gerencie sua conta
					</Button>
					{	isAdmin &&
						<Button redirect onclick={'/admin'}>
							Acesse o painel Admin
						</Button>
					}
				</Column>
			</Container>

			<Container>
				<Text textOption='subtitle'>
					Conheça nossos bots
				</Text>
				<Button tosuccess redirect onclick={'/user/plans'}>
					Contrate um plano
				</Button>
			</Container>

			{
				domainConfig.visibleElements.reports &&
				<Container>
					<Text textOption='subtitle'>
						Conheça nossos resultados
					</Text>
					<Button redirect onclick={'/reports'}>
						Estatísticas
					</Button>
				</Container>
			}

			<Container>
				<Text textOption='subtitle'>
					Use nosso bot
				</Text>
				<Button redirect onclick={'/download'}>
					Faça o download
				</Button>
			</Container>

			<Container>
				<Column>
					<Text textOption='subtitle'>
					Redes Sociais
					</Text>
					<Button onclick={() => window.open(domainConfig.link.instagram)}>
					Instagram
					</Button>
					<Button onclick={() => window.open(domainConfig.link.whatsapp)}>
					Whatsapp (Suporte)
					</Button>
				</Column>
			</Container>
			<Circle from='whatsapp' url={domainConfig.link.whatsapp} />
		</>
	)

}
