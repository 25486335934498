import React from 'react'
import StyledLink from './styles'

export default function Link({
	children,
	to,
	target = '',
	download = false
} : {
	children: any
	to: string,
	target?: string
	download?: boolean
}){

	return (
		<StyledLink
			to={to}
			target={target}
			download={download}
		>
			{children}
		</StyledLink>
	)

}
