import React from 'react'
import StyledContainer from './styles'

export default function Container({
	children,
	fullWidth,
	withoutMargin,
} : {
	children: any,
	fullWidth?: boolean,
	withoutMargin?: boolean,
}){

	return (
		<StyledContainer
			fullWidth={fullWidth}
			withoutMargin={withoutMargin}
		>
			{children}
		</StyledContainer>
	)

}
