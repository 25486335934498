import React from 'react'
import Container from '../../components/Container/styles'
import Text from '../../components/Text'
import BackButton from '../../components/Button/Back'

export default function Terms(){

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption="title">
				Termos de uso
				</Text>
			</Container>

			<Container>
				<Text textOption="linnedSubtitle">
				Política de Reembolso
				</Text>
				<Text fullWidth>
				Este Termo de Uso estabelece as condições para a solicitação de reembolso de valores pagos por mensalidades, conforme a Lei do Arrependimento (Art. 49 do Código de Defesa do Consumidor), no caso de desistência do produto ou serviço dentro de 7 (sete) dias. Ao contratar nossos serviços, você concorda com os termos e condições abaixo estabelecidos.
					<br /><br />
				Período de Arrependimento:
					<br /><br />
				O consumidor tem o direito de desistir do contrato, sem qualquer ônus, no prazo de até 7 (sete) dias corridos, contados a partir da data da assinatura do contrato ou do recebimento do produto/serviço.
					<br /><br />
				Solicitação de Reembolso:
					<br /><br />
				Para solicitar o reembolso, o consumidor deve enviar uma comunicação por escrito, para algum dos contatos fornecidos pela empresa, dentro do período de 7 (sete) dias mencionados acima.
					<br />
				A mensagem deve conter o nome completo do consumidor, o email da conta usada e a solicitação explícita de reembolso.
					<br /><br />
				Cálculo do Valor de Reembolso:
					<br />
				O valor do reembolso será proporcional ao tempo de não utilização do serviço dentro do período da mensalidade.
					<br />
				Será descontado do valor total pago o período em que o serviço foi utilizado pelo consumidor até a data da solicitação formal de cancelamento.
					<br />
				O cálculo será feito da seguinte forma: (Valor Total da Mensalidade / 30 dias) x (30 dias - número de dias utilizados até a solicitação de cancelamento).
					<br /><br />
				Processamento do Reembolso:
					<br /><br />
				Após a recepção e validação da solicitação de cancelamento, o reembolso será processado em até 10 (dez) dias úteis.
					<br /><br />
				Exceções e Considerações:
					<br /><br />
				Caso o serviço contratado possua alguma promoção ou desconto específico, o valor proporcional será calculado com base no valor pago, e não no valor cheio da mensalidade.
					<br />
				Se houver utilização parcial de serviços adicionais, estes também serão considerados no cálculo do reembolso proporcional.
					<br /><br />
				Contato:
					<br /><br />
				Para quaisquer dúvidas ou solicitações relacionadas ao reembolso, o consumidor pode entrar em contato com nosso suporte ao cliente através do telefone (77) 99994-8001.
					<br />
				Ao aceitar este Termo de Uso, o consumidor declara estar ciente e de acordo com as condições estabelecidas para o reembolso proporcional no caso de desistência do serviço dentro do período de 7 (sete) dias.
				</Text>
			</Container>
		</>
	)

}
