import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	leftAlign?: boolean
	small?: boolean
}

export default styled.div<styles>`
	display: flex;
	flex-direction: column;
	align-items: ${({leftAlign}) => leftAlign ? 'flex-start' : 'center'};

	margin: ${({small}) => small ? '2px' : styleguide.margin.form};
`
