import { defaultPath } from '../../App'
import token from '../Token'

export interface Response {
	status: number,
	msg: string,
	data?: any
}

type sendToken = 'false' | 'admin' | 'user'

export default async function ApiRequest(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization':
			sendToken == 'admin' ? `${token(true)}`
				: sendToken == 'user' ? `${token()}`
					: ''
		},
		body: JSON.stringify(data)
	})

	const response = await request.json() as Response

	return response

}

export async function downloadRequest(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization':
			sendToken == 'admin' ? `${token(true)}`
				: sendToken == 'user' ? `${token()}`
					: ''
		},
		body: JSON.stringify(data)
	})

	const response = await request.blob() as Blob

	return response

}

export async function uploadRequest(
	route: string,
	data = {},
	sendToken: sendToken = 'false',
){

	const apiPath = `${defaultPath}/api/v1`

	if(route.startsWith('/')) route = route.replace('/', '')

	const finalRoute = route.includes('internal/asset')
		? `${apiPath?.replace('/api/v1', '')}/${route}`
		: `${apiPath}/${route}`

	const request = await fetch(finalRoute, {
		method: 'POST',
		headers: {
			'Authorization':
			sendToken == 'admin' ? `${token(true)}`
				: sendToken == 'user' ? `${token()}`
					: ''
		},
		body: data as BodyInit
	})

	const response = await request.json()

	return response as Response

}
