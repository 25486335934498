import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'

import Header from '../../../components/Header'
import Container from '../../../components/Container/styles'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Input, { InputChange } from '../../../components/Input/Text'
import BackButton from '../../../components/Button/Back'
import Popup from '../../../components/Popup'
import Loading from '../../../components/Loading'
import ApiRequest, { Response } from '../../../global/Api'
import token from '../../../global/Token'
import Row from '../../../components/Row'
import Column from '../../../components/Column'
import { useDomainConfig } from '../../../global/Domain'

type markets = 'rollover' | 'valuebet' | 'alavancagem'

interface planData {
	rollover: {
		logins: string[]
		variations: string[]
		quantity: number
		subscriptionValidate: number
	},
	valuebet: {
		logins: string[]
		variations: string[]
		quantity: number
		subscriptionValidate: number
	},
	alavancagem: {
		logins: string[]
		variations: string[]
		quantity: number
		subscriptionValidate: number
	},
}

interface affiliateData {
	code: string
}

export default function Account(){

	const domainConfig = useDomainConfig()

	const betLoginRef = useRef({} as planData)
	const [planData, setPlanData] = useState({} as planData)
	const [valuebetPlanData, setValuebetPlanData] = useState({}as { [key: string]: any })
	const [subscriptionValidate, setSubscriptionValidate] = useState({
		rollover: '', valuebet: '', alavancagem: ''
	})

	const [affiliateData, setAffiliateData] = useState({} as affiliateData)
	const [copyButtonActived, setCopyButtonActived] = useState(true)
	// console.log({copyButtonActived})
	const [popupResponse, setPopupResponse] = useState({} as Response)
	// const [forceRender, setForceRender] = useState(0)

	useEffect(() => {

		getLoginBetData()
		getAffiliateData()

	}, [])

	async function getLoginBetData(){

		const plans = await ApiRequest('global/show/plans')

		const plansMap = plans.data
			.find((plan: any) => plan.name == 'Valuebet')['price']['variation']
			.map((variation: any) => ({ [variation.id]: variation.title }))

		const plansTrated: { [key: string]: any } = {}
		plansMap.forEach((plan: any) => {

			const [key, value] = Object.entries(plan)[0]
			plansTrated[key] = value

		})


		setValuebetPlanData(plansTrated)

		const response = await ApiRequest(`user/get/loginBet/${token()}`)

		const { rollover, valuebet, alavancagem } = response.data

		betLoginRef.current = response.data
		setPlanData({ rollover, valuebet, alavancagem })

		const validate = {
			rollover: betLoginRef.current.rollover.subscriptionValidate < Date.now()
				? 'Validade Expirada'
				: 'Validade: ' + moment.unix(betLoginRef.current.rollover.subscriptionValidate/1000).format('DD/MM/YYYY'),
			valuebet: betLoginRef.current.valuebet.subscriptionValidate < Date.now()
				? 'Validade Expirada'
				: 'Validade: ' + moment.unix(betLoginRef.current.valuebet.subscriptionValidate/1000).format('DD/MM/YYYY'),
			alavancagem: betLoginRef.current.alavancagem.subscriptionValidate < Date.now()
				? 'Validade Expirada'
				: 'Validade: ' + moment.unix(betLoginRef.current.alavancagem.subscriptionValidate/1000).format('DD/MM/YYYY'),
		}

		setSubscriptionValidate({
			rollover: validate.rollover,
			valuebet: validate.valuebet,
			alavancagem: validate.alavancagem
		})

	}

	async function updateLoginBet(market: markets){

		console.log('current', betLoginRef.current[market].logins)

		const response = await ApiRequest(`user/update/loginBet/${token()}`, {
			market,
			logins: betLoginRef.current[market].logins
		})

		setPopupResponse(response)

	}

	async function getAffiliateData(){

		const response = await ApiRequest(`user/get/affiliate/${token()}`)

		const { code } = response.data

		setAffiliateData({ code })

	}

	async function updateAffiliate(){

		// console.log('current', affiliateData)

		const response = await ApiRequest(`user/update/affiliate/${token()}`, affiliateData)

		setPopupResponse(response)

	}

	function rolloverComponent(){

		return (
			<Container key={'rolloverLogins'}>
				{ planData.rollover?.quantity == undefined
					? <Loading />
					: planData.rollover?.quantity != 0
						? <>
							<Text textOption='subtitle'>
							Editar logins permitidos do Rollover ({subscriptionValidate.rollover})
							</Text>
							<Row>
								{ LoginList('rollover', planData.rollover?.subscriptionValidate < Date.now()) }
							</Row>
							<Button
								toedit
								onclick={() => updateLoginBet('rollover')}
							>
							Editar
							</Button>
						</>
						: <Text textOption='subtitle'>
						Sem bots de Rollover
						</Text>
				}
			</Container>
		)

	}

	function valuebetComponent(){

		return (
			<Container key={'valuebetLogins'}>
				{ planData.valuebet?.quantity == undefined
					? <Loading />
					:planData.valuebet?.quantity != 0
						? <>
							<Text textOption='subtitle'>
							Editar logins permitidos do Valuebet ({subscriptionValidate.valuebet})
							</Text>
							<Row>
								{ LoginList('valuebet', planData.valuebet?.subscriptionValidate < Date.now()) }
							</Row>
							<Button
								toedit
								onclick={() => updateLoginBet('valuebet')}
							>
							Editar
							</Button>
						</>
						: <Text textOption='subtitle'>
						Sem bots de Valuebet
						</Text>
				}
			</Container>
		)

	}

	function alavancagemComponent(){

		return (
			<Container key={'alavancagemLogins'}>
				{ planData.alavancagem?.subscriptionValidate == undefined
					? <Loading />
					:planData.alavancagem?.quantity != 0
						? <>
							<Text textOption='subtitle'>
							Editar logins permitidos do Alavancagem ({subscriptionValidate.alavancagem})
							</Text>
							<Row>
								{ LoginList('alavancagem', planData.alavancagem?.subscriptionValidate < Date.now()) }
							</Row>
							<Button
								toedit
								onclick={() => updateLoginBet('alavancagem')}
							>
							Editar
							</Button>
						</>
						: <Text textOption='subtitle'>
						Sem bots de Alavancagem
						</Text>
				}
			</Container>
		)

	}

	function affiliateComponent(){

		return (
			<Container key={'affiliate'}>
				<Text textOption='title'>
				Afiliado
				</Text>
				{ affiliateData
					? <Column>
						<Input
							placeholder='Sem código'
							bloqued={affiliateData.code ? true : false}
							defaultValue={affiliateData.code}
							onChange={(event: any) => {

								setCopyButtonActived(false)
								setAffiliateData({ code: event.target.value })

							}}
						>
							Código
						</Input>
						{ affiliateData.code && (
							<Text
								textOption='subtitle'
								fullWidth
								haveMargin={false}
							>
								{`${window.location.origin}/register/${affiliateData.code}`}
							</Text>
						)}
						<Button
							active={copyButtonActived}
							onclick={() => {

								setCopyButtonActived(false)
								navigator.clipboard.writeText(`${window.location.origin}/register/${affiliateData.code}`)

							}}
						>
							{copyButtonActived ? 'Copiar Link' : 'Copiado'}
						</Button>
						<br />
						<Button
							toedit
							onclick={() => updateAffiliate()}
						>
							Editar Dados
						</Button>
					</Column>
					: <Loading />
				}
			</Container>
		)

	}

	function LoginList(market: markets, isExpired: boolean){

		const lista: JSX.Element[] = []

		for(let i = 0; i < planData[market]?.quantity; i++){

			betLoginRef.current[market].logins[i] = planData[market]?.logins[i] || ''

			const variation = planData[market].variations[i]

			lista.push(
				<Column key={i}>
					{variation &&
						<Text centerAlign>
							{valuebetPlanData[variation] || 'Ilimitado'}
						</Text>
					}
					<Input
						extrasmall
						defaultValue={planData[market]?.logins[i]}
						placeholder='usuarioBetano123'
						bloqued={isExpired}
						onChange={(event: InputChange) => betLoginRef.current[market].logins[i] = event.target.value }
					>
					</Input>
				</Column>
			)

		}

		return lista

	}

	return (
		<>
			<BackButton />
			<Header />
			<Container key={'title'}>
				<Text textOption='title'>
				Edite as informações da sua conta
				</Text>
			</Container>

			{domainConfig.visibleElements.userControlPanel.rollover && rolloverComponent()}
			{domainConfig.visibleElements.userControlPanel.valuebet && valuebetComponent()}
			{domainConfig.visibleElements.userControlPanel.alavancagem && alavancagemComponent()}
			{domainConfig.visibleElements.affiliate && affiliateComponent()}

			<Popup data={{...popupResponse}}  />
		</>
	)

}
