import React, { useEffect, useRef, useState } from 'react'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button'
import BackButton from '../../../../components/Button/Back'
import Container from '../../../../components/Container'
import ApiRequest, { Response } from '../../../../global/Api'
import Column from '../../../../components/Column'
import Input, { InputChange } from '../../../../components/Input/Text'
import Popup from '../../../../components/Popup'
import Row from '../../../../components/Row'

export default function ConfigUsers(){

	type PlanPermissionType = 'rollover' | 'valuebet'

	interface userMap {
		_id: string
		name: string
		email: string
		phone: string
		document: string
		password: string
		planPermissions: {
			rollover: {
				quantity: string
				subscriptionValidate: string
				logins?: string[]
			}
			valuebet: {
				quantity: string
				subscriptionValidate: string
				logins?: string[]
			}
		}

		deleting?: boolean
	}

	const usersBase = useRef() as any
	const [users, setUsers] = useState([])
	const usersRef = useRef({} as Map<string, userMap>)

	const [popupResponse, setPopupResponse] = useState({} as Response)

	useEffect(() => { listUsers() }, [])

	async function listUsers(){

		const response = await ApiRequest('admin/show/user', {}, 'admin')

		if(response.status == 200){

			usersBase.current = response.data
			setUsers(usersBase.current)

		}

	}

	async function confirmDeletion(userID: string){

		const response = await ApiRequest('admin/delete/user', {
			filter: { _id: userID}
		}, 'admin')

		visualDeletion(userID)

		setPopupResponse(response)

	}

	function usersRender(){

		return users.map((user: userMap, index: number)  => (
			user.deleting
				?
				<Container>
					<Text textOption='subtitle' haveMargin>
						{user.name}
					</Text>
					<Button todelete onclick={() => confirmDeletion(user._id)}>
						DELETAR (IRREVERSÍVEL)
					</Button>
					<Button onclick={() => clearPopupDeletion(user._id)}>
						Voltar
					</Button>
				</Container>
				:
				<Container fullWidth key={user._id}>
					<Column>
						<Text textOption='subtitle' haveMargin>
							{user.name}
							{parseInt(user.planPermissions.rollover.subscriptionValidate) > Date.now() ? '🎁' : ''}
							{parseInt(user.planPermissions.valuebet.subscriptionValidate) > Date.now() ? '🤖' : ''}
							<br />
							({user.email})
						</Text>
						<Row totalWidth>
							<Button small todelete
								onclick={() => popupDeletion(user._id)}
							>
								Deletar
							</Button>
							<Button small toedit redirect
								onclick={user._id}
							>
								Editar
							</Button>
						</Row>
					</Column>
				</Container>
		))

	}

	function filterUsers(search: string){

		// usersRef.current
		search == ''
			? setUsers(usersBase.current)
			: setUsers(usersBase.current.filter((user: userMap) => user.email.includes(search)))

	}

	function popupDeletion(userID: string){

		setUsers(
			usersBase.current
				.map((user: userMap) => ( user._id == userID ? { ...user, deleting: true } : { ...user }  ))
		)

	}

	function clearPopupDeletion(userID: string){

		setUsers(
			usersBase.current
				.map((user: userMap) => ( user._id == userID ? { ...user, deleting: false } : { ...user }  ))
		)

	}

	function visualDeletion(userID: string){

		setUsers(
			usersBase.current
				.filter((user: userMap) => user._id != userID)
		)

	}

	function activedUsers(){

		return users.filter((user: userMap) => {

			const products = Object.keys(user.planPermissions)
			let actived = false

			products.forEach((product: string) => {

				const key = product as PlanPermissionType
				if(parseInt(user.planPermissions[key]['subscriptionValidate']) > Date.now()) actived = true

			})

			return actived

		}).length.toString()

	}

	return (
		<>
			<BackButton />

			<Container>
				<Text textOption='title'>
				Edite seus usuários
				</Text>
			</Container>

			<Container>
				<Row>
					<Column>
						<Text fullWidth>
							Usuários cadastrados: <b>{users.length || '--'}</b>
						</Text>
						<Text fullWidth>
							Usuários ativos: <b>{ activedUsers() || '--' }</b>
						</Text>
					</Column>
					<Input
						onChange={(event: InputChange) => filterUsers(event.target.value)}
					>
						Pesquise por Email
					</Input>
					<Column>
						<Text textOption='subtitle'>
							Filtros: (em breve)
						</Text>
						<br />
						<Text fullWidth>
							<input type='checkbox' checked />
							❌ Usuários inativos
						</Text>
						<Text fullWidth>
							<input type='checkbox' checked />
							🎁 Usuário ativo Rollover
						</Text>
						<Text fullWidth>
							<input type='checkbox' checked />
							🤖 Usuário ativo Valuebet
						</Text>
					</Column>
				</Row>
			</Container>

			{
				users.length > 0
					? <Row>
						{usersRender()}
					</Row>
					: <Container >
						<Text textOption='subtitle'>
						Sem usuários...
						</Text>
					</Container>
			}


			<Popup data={{...popupResponse}} />
		</>
	)

}
