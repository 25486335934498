import React from 'react'
import Container from '../../../../components/Container'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button'
import BackButton from '../../../../components/Button/Back'

export default function ConfigGlobal(){

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='title'>
					Configurações Globais
				</Text>
			</Container>
			<Container fullWidth>
				<Button
					redirect
					onclick={'./affiliate'}
				>
					Configurações de Afiliado
				</Button>
			</Container>
		</>
	)

}
