import React, { useState } from 'react'
import StyledButton from './styles'
import { useNavigate } from 'react-router-dom'

export default function Button({
	children,
	onclick,

	active = true,
	redirect,

	download,
	toedit,
	tosuccess,
	todelete,
	small,
	isfixed,
} : {
	children: any,
	onclick: any,

	active?: boolean
	redirect?: boolean
	download?: boolean
	toedit?: boolean
	tosuccess?: boolean
	todelete?: boolean
	small?: boolean
	isfixed?: boolean
}){

	const navigate = useNavigate()

	const [disabled, setDisabled] = useState(!active)
	const [text, setText] = useState(children)

	const downloadTexts = [
		'Baixando...',
		'O Download pode demorar um pouco.',
		'Aguarde o Download'
	]

	return (
		<StyledButton
			small={small}
			toedit={toedit}
			tosuccess={tosuccess}
			todelete={todelete}
			disabled={disabled}
			isfixed={isfixed}

			onClick={redirect ? () => navigate(onclick) : async () => {

				let index = 0
				let intervalDownload
				if(download){

					setText(downloadTexts[index++ % downloadTexts.length])
					intervalDownload = setInterval(() => setText(downloadTexts[index++ % downloadTexts.length]), 5000)

				}

				setDisabled(true)
				await onclick()
				setDisabled(false)

				if(intervalDownload){

					setText(children)
					clearInterval(intervalDownload)

				}

			}}
		>
			{text}
		</StyledButton>
	)

}
