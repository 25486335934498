import React, { useEffect, useRef, useState } from 'react'
import Container from '../../../../components/Container'
import ApiRequest, { Response } from '../../../../global/Api'
import Loading from '../../../../components/Loading'
import Text from '../../../../components/Text'
import Input, { InputChange } from '../../../../components/Input/Text'
import Row from '../../../../components/Row'
import BackButton from '../../../../components/Button/Back'
import SelectInput from '../../../../components/Input/Select'
import Column from '../../../../components/Column'
import Button from '../../../../components/Button'
import Popup from '../../../../components/Popup'

export default function ConfigUser(){

	const user = {
		_id: '',
		name: '',
		email: '',
		phone: '',
		document: '',
		password: '',
		affiliation: '',
		planPermissions: {
			rollover: {
				quantity: '0',
				subscriptionValidate: '0',
				logins: [''],
				variations: ['']
			},
			valuebet: {
				quantity: '0',
				subscriptionValidate: '0',
				logins: [''],
				variations: ['']
			},
			alavancagem: {
				quantity: '0',
				subscriptionValidate: '0',
				logins: [''],
				variations: ['']
			},
		},
		affiliate: {
			code: '',
			revshare: null as null | number,
			suitpayUser: null as null | string
		}
	}

	const [popupResponse, setPopupResponse] = useState({} as Response)
	const [userInfo, setUserInfo] = useState(user)
	const [planInfo, setPlanInfo] = useState([{}] as any[])
	const userRef = useRef(user)

	const dia = 1000 * 60 * 60 * 24

	async function getUser(_id: string) {

		const response = await ApiRequest('admin/get/user', {
			filter: { _id }
		}, 'admin')

		if(response.status == 400) return setPopupResponse(response)

		setUserInfo({
			...user,
			...response.data,
			planPermissions: {
				...response.data.planPermissions,
				alavancagem: response.data.planPermissions.alavancagem || user.planPermissions.alavancagem
			}
		})

	}

	async function getPlans(){

		const response = await ApiRequest('global/show/plans')

		if(response.status == 400) return setPopupResponse(response)

		setPlanInfo(response.data)

	}

	async function updateUser(){

		const updated: any = { ...userRef.current }
		delete updated._id

		// console.log({updated})

		const response = await ApiRequest('admin/update/user', {
			filter: { _id: userInfo._id },
			updated
		}, 'admin')

		setPopupResponse(response)

	}

	function personalDataComponent(){

		return (
			<Container
				fullWidth
			>
				<Text textOption='subtitle'>
					Dados Pessoais
				</Text>
				<Input
					isRow small
					defaultValue={userInfo.email}
					onChange={(event: any) => userRef.current.email = event.target.value }
					onMount={() => {

						userRef.current = { ...userInfo }
						userRef.current.planPermissions.valuebet.subscriptionValidate = ((parseInt(userInfo.planPermissions['valuebet']['subscriptionValidate']) - Date.now()) / dia).toFixed(2)
						userRef.current.planPermissions.rollover.subscriptionValidate = ((parseInt(userInfo.planPermissions['rollover']['subscriptionValidate']) - Date.now()) / dia).toFixed(2)
						userRef.current.planPermissions.rollover.subscriptionValidate = ((parseInt(userInfo.planPermissions['alavancagem']?.['subscriptionValidate'] || '0') - Date.now()) / dia).toFixed(2)

					}}
				>
					Email:
				</Input>
				<Input
					isRow small
					defaultValue={userInfo.phone}
					onChange={(event: any) => userRef.current.phone = event.target.value }
				>
					Telefone:
				</Input>
				<Input
					isRow small
					defaultValue={userInfo?.document || ''}
					onChange={(event: any) => userRef.current.document = event.target.value }
				>
					Documento:
				</Input>
				<Input
					isRow small
					defaultValue={userInfo.password}
					onChange={(event: any) => userRef.current.password = event.target.value }
				>
					Senha:
				</Input>
				<Input
					isRow small
					placeholder='Sem Afiliação'
					defaultValue={userInfo.affiliation}
					onChange={(event: any) => userRef.current.affiliation = event.target.value }
				>
					Afiliação (ID):
				</Input>
			</Container>
		)

	}

	function planDataComponent(){

		return(
			<Container
				fullWidth
			>
				<Text textOption='subtitle'>
					Dados do Plano
				</Text>
				<Input
					isRow small type='number'
					defaultValue={userInfo.planPermissions['rollover']['quantity']}
					onChange={(event: InputChange) => userRef.current.planPermissions.rollover.quantity = event.target.value }
				>
					Qtd. Rollover
				</Input>
				<Input
					isRow small type='number'
					defaultValue={((parseInt(userInfo.planPermissions['rollover']['subscriptionValidate']) - Date.now()) / dia).toFixed(2)}
					onChange={(event: InputChange) => userRef.current.planPermissions.rollover.subscriptionValidate = event.target.value }
				>
					Validade Rollover (dias)
				</Input>
				<Input
					isRow small type='number'
					defaultValue={userInfo.planPermissions['valuebet']['quantity']}
					onChange={(event: InputChange) => userRef.current.planPermissions.valuebet.quantity = event.target.value }
				>
					Qtd. Valuebet
				</Input>
				<Input
					isRow small type='number'
					defaultValue={((parseInt(userInfo.planPermissions['valuebet']['subscriptionValidate']) - Date.now()) / dia).toFixed(2)}
					onChange={(event: any) => userRef.current.planPermissions.valuebet.subscriptionValidate = event.target.value }
				>
					Validade Valuebet (dias)
				</Input>
				<Input
					isRow small type='number'
					defaultValue={userInfo.planPermissions['alavancagem']?.['quantity'] || '0'}
					onChange={(event: InputChange) => userRef.current.planPermissions.alavancagem.quantity = event.target.value }
				>
					Qtd. Alavancagem
				</Input>
				<Input
					isRow small type='number'
					defaultValue={((parseInt(userInfo.planPermissions['alavancagem']?.['subscriptionValidate'] || '0') - Date.now()) / dia).toFixed(2)}
					onChange={(event: any) => userRef.current.planPermissions.alavancagem.subscriptionValidate = event.target.value }
				>
					Validade Alavancagem (dias)
				</Input>
			</Container>
		)

	}

	function affiliateDataComponent(){

		return (
			<Container fullWidth>
				<Text textOption='subtitle'>
					Dados de Afiliado
				</Text>
				<Column>
					<Input
						isRow small
						placeholder='Sem código'
						defaultValue={userInfo.affiliate.code}
						onChange={(event: any) => userRef.current.affiliate.code = event.target.value }
					>
						Código
					</Input>
					<Input
						isRow small type='number'
						placeholder='Padrão'
						defaultValue={userInfo.affiliate.revshare?.toString() || ''}
						onChange={(event: any) => userRef.current.affiliate.revshare = parseInt(event.target.value) }
					>
						Revshare
					</Input>
					<Input
						isRow small
						placeholder='Não cadastrado'
						defaultValue={userInfo.affiliate.suitpayUser || ''}
						onChange={(event: any) => userRef.current.affiliate.suitpayUser = event.target.value }
					>
						Usuário Suitpay
					</Input>
				</Column>
			</Container>
		)

	}

	function loginValuebetData(){

		const gameData = userInfo.planPermissions.valuebet
		const { variations, quantity } = gameData
		let logins = gameData.logins

		for(let i = 0; i < parseInt(quantity); i++) if(logins[i] == null) logins[i] = ''
		logins = logins.map((login: any) => login ? login : '')

		const variationOptions = planInfo
			.find(plan => plan.id == '#1')['price']['variation']

		return (
			<>
				{logins.map((login: string, i: number) => (
					<Row small totalWidth>
						<SelectInput
							optionsArray={variationOptions}
							selectedValue={variationOptions.find((opt: any) => opt.id == variations[i])?.id || '#0'}
							onChange={(value: any) => userRef.current.planPermissions.valuebet.variations[i] = value}
						/>
						<Input
							defaultValue={login}
							onChange={(event: any) => userRef.current.planPermissions.valuebet.logins[i] = event.target.value}
							isRow
							small
						/>
					</Row>
				))}
			</>
		)

	}

	function loginRolloverData(){

		const gameData = userInfo.planPermissions.rollover
		const { variations, quantity } = gameData
		let logins = gameData.logins

		for(let i = 0; i < parseInt(quantity); i++) if(logins[i] == null) logins[i] = ''
		logins = logins.map((login: any) => login ? login : '')

		const variationOptions = planInfo
			.find(plan => plan.id == '#2')['price']['variation']

		return (
			<>
				{logins.map((login: string, i: number) => (
					<Row small>
						<SelectInput
							isRow
							optionsArray={variationOptions}
							selectedValue={variationOptions.find((opt: any) => opt.id == variations[i])?.id || '#0'}
							onChange={(value: any) => userRef.current.planPermissions.rollover.variations[i] = value}
						/>
						<Input
							defaultValue={login}
							onChange={(event: any) => userRef.current.planPermissions.rollover.logins[i] = event.target.value}
							isRow
							small
						/>
					</Row>
				))}
			</>
		)

	}

	useEffect(() => {

		const path = window.location.pathname
		const id = path.replace('/admin/configs/users/', '')

		getPlans()
		getUser(id)

	}, [])

	return (
		userInfo._id == ''
		|| planInfo[0].id == undefined
	)
		? <Loading />
		:(
			<>
				<BackButton />
				<Container>
					<Text textOption='title'>
						{userInfo.name} - {userInfo._id}
					</Text>
				</Container>
				<Column>
					{personalDataComponent()}
					{planDataComponent()}
					{affiliateDataComponent()}

					<Container
						fullWidth
					>
						<Text textOption='subtitle'>
							Dados de Login
						</Text>
						{loginValuebetData()}
						{loginRolloverData()}
					</Container>
				</Column>

				<Button
					isfixed
					toedit
					onclick={() => updateUser()}
				>
					Editar
				</Button>

				<Popup data={popupResponse} />
			</>
		)

}
