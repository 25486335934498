import React from 'react'
import { StyledCircle } from './styles'
import { Link } from 'react-router-dom'

type From = 'whatsapp' | 'instagram'

interface CircleIcon {
	from: From,
	url: string,
	left?: boolean
}

export default function Circle({
	from, url,
	left,
}: CircleIcon){

	const images = {
		whatsapp: 'https://cdn-icons-png.flaticon.com/512/3670/3670051.png',
		instagram: 'https://cdn.pixabay.com/photo/2021/06/15/12/14/instagram-6338393_1280.png'
	}

	return (
		<a target='_blank' href={url}>
			<StyledCircle
				title={from}
				src={images[from]}
				left={left}
			/>
		</a>
	)

}
