const videos = [
	{
		title: 'Primeiros Passos',
	},
	{
		title: 'Dica: VPS',
	},
	{
		title: 'Instalar Bot',
	},
	{
		title: 'Configurando VPS e Proxy',
	},
	{
		title: 'Usando a VPS',
	},
	{
		title: 'Usando Bot Rollover',
	},
	{
		title: 'Usando o Bot Betano (Valuebet)',
	},
	{
		title: 'Usando o Bot Superbet (Valuebet)',
	},
]

// Base -> https://www.youtube.com/embed/

export default videos
